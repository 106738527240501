<template>
  <section class="menu" id="menu">
    <div class="navMenu mobile">
      <select v-model="selectedMenu" @change="scrollToTitle(selectedMenu.id)">
        <template v-for="item in items" :key="item.id">
          <option :value="item" v-if="!item.ocultar">{{ item.name }}</option>
        </template>
      </select>
    </div>
    <div class="navMenu desktop">
      <div class="panel">
        <div class="panel-container" ref="container">
          <div class="logo">
              <img src="@/assets/img/logo.svg">
          </div>
          <div class="items">
            <template v-for="item in items" >
                <button  :class="'menuButton'+item.id"
                  @click="scrollToTitle(item.id)" v-if="!item.ocultar">{{item.name}}<br>{{item.description}}</button>
              </template>
            <br>
          </div>
        </div>
      </div>
    </div>
      <div class="panel">
        <div class="panel-container" ref="scrollContainer" @scroll="onScroll">
          <template v-for="item in items" :key="item.id" >
            <div v-if="!item.ocultar" >
              <div class="row m-0">
                <div v-if="item.name" class="col m-0 name" style="font-size: 1.5rem !important; margin-top: 1.1rem !important; font-weight: bold; height: fit-content; margin-right: 1vh;">
                    <span v-if="item.name">{{ item.name }}</span></div>
              </div>
              <div class="menuPage" :id="'page'+item.id" >
                <div :ref="'page'+item.id" class="menuItems">
                  <template v-for="itemCh in item.items" :key="itemCh.id">
                    <MenuItem :item="itemCh" v-if="!itemCh.ocultar" :ocultar="item.ocultar"></MenuItem>
                  </template>
                </div>
                <div class="titles" >{{item.name}}</div>
              </div>
              <hr>
            </div>
          </template>
        </div>
      </div>


  </section>
</template>
<script>
    import MenuItem from './MenuItem.vue';
    import MenuService from '@/services/dataServices/menu.service';
    export default {
      name: 'MenuPage',
      components:{
        MenuItem
      },
      data(){
        return {
          items:[],
          selectedMenu: null
        }
      },
      methods:{
        getSelectedMenu(){
          const time = new Date().getHours();
          var defaultItem = this.items[0];
          var biggerTime = 0;
          for (const item of this.items) {
            for (const itemTime of item.startTimes) {
              if (time >= parseInt(itemTime.value) && parseInt(itemTime.value) >= biggerTime) {
                defaultItem = item;
                biggerTime = parseInt(itemTime);
              }
            }
          }
          this.scrollToTitle(defaultItem.id)
          return defaultItem;
        },
        scrollToTitle(itemId) {
          
          let buttons = document.querySelectorAll('[class*="menuButton"]');
          for(const button of buttons){
            if(button.classList.contains('menuButton'+itemId)){
              button.classList.add("active")
            } else {
              button.classList.remove("active")
            }
          }

          this.selectedMenu = this.items.find(item => item.id === itemId)

          let target = document.getElementById("page"+itemId);
          if (target) {
            // sin topper
            target.parentNode.parentNode.scrollTop = target.offsetTop;
            //con topper
            //window.scrollTo(0, target.offsetTop + 1)
          }
        },
        onScroll() {
          const container = this.$refs.scrollContainer;
          const scrollTop = container.scrollTop;
          const containerHeight = container.clientHeight;

          for (const item of this.items) {
            if(!item.ocultar){
              const section = this.$refs['page' + item.id][0];
              const sectionTop = section.offsetTop;
              const sectionHeight = section.offsetHeight;

              if (scrollTop >= sectionTop && scrollTop < sectionTop + sectionHeight) {
                document.querySelectorAll('[class*="menuButton"]').forEach(element => element.classList.remove('active'));
                document.querySelector('[class*="menuButton'+item.id+'"]').classList.add("active")
                this.selectedMenu = item;
                break;
              }
            }
          }
        }
      },
      async mounted(){
        try {
            MenuService.getActive().then((res) => {
              this.items = JSON.parse(JSON.stringify(res[0].menujson));

              this.$nextTick(() => {
                for (const item of this.items) {
                  if(!item.ocultar){
                    const menuPage = this.$refs['page' + item.id][0];
                    menuPage.style.height = Math.ceil(menuPage.offsetHeight / 2) * 1.05 + 'px';
                    menuPage.classList.add('column-count-2');
                  }
                }

                this.selectedMenu = this.getSelectedMenu();
              });
            }).catch((e) => {
              this.items = [{ id: '0', name: 'MENU NO DISPONIBLE, INTENTE MAS TARDE', price: '', description: '', startTimes:[{'value':'00'}] }];
            });
          } catch (e) {
            console.error('An error occurred:', e);
          }
      },
    }
</script>